@tailwind base;
@tailwind components;
@tailwind utilities;

html{
    font-size: 16px;
}

*{
    font-family: 'Poiret One', cursive;
    font-weight: bolder;
    font-size: 1.4rem;
}

.srh{
    display: none;
}

.navv div{
    padding : 1%;
    margin: 1% 1%;
}

.usr {
    width : 50%;
}

.home{
    padding: 5%;

}

.lnk{
    color: white;
    background-color: black;
    padding: 15px;

}

.lnk:hover{
    background-color: grey;
}

.navv{
    background-image: url("../src/components/navborder.png");
    background-size: cover; 
  background-repeat: no-repeat;
  background-position: center; 
}

a{
    text-decoration: none;
    color: black;
}

img:hover{
    cursor: pointer;
}

.topic button{
    background-color: gray;
}
.addcmnt button{
    background-color: gray;
}

button:hover{
    cursor: pointer;
    background-color: white;
}

.mypst .lnk, .home .lnk, .sprt .lnk{
    padding: 4%;
}


.topic{
    font-family: 'Poiret One', cursive;
    font-size: 3.5rem;
    
    border-radius: 20px;
    padding: 1%;
}


.about{
    font-size: 1.5rem;
}

.logn, .sgnup{
    width: 30%;
}

.reactions img{
    width : 30px;
    height : 30px;
}

.cmntss{
    font-size: 2rem;
}

.cmntusr{
    font-size : xx-large;
}

.btnlgout{
    margin: auto;
}

.task-list {
    font-family: Arial, sans-serif;
    margin: 100px 0;
    padding: 20px;
    width: 500px;
    max-width: 100vw;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .add-task {
    display: flex;
    margin-bottom: 20px;
  }
  
  .add-task input[type="text"] {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .add-task button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    background-color: #fca311;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  li div {
    flex: 1;
    margin-right: 10px;
    text-decoration: none;
    color: #333;
  }
  
  li button {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
  }

  .ii:hover{
    background-color: rgb(73, 1, 1);
  }

  
  @media (max-width : 450px) {
    .main_div{
      flex-direction: column;
    }
    .task-list{
      width: 80%;
      margin: auto;
      margin-top: 20%;
    }
    .info_div{
      margin: 10% 0 0 0;
    }
  }

  .task-details-container {
    max-width: 800px;
    margin: 100px auto;
    padding: 20px;
  }
  
  .task-details-heading {
    text-align: center;
    color: #333;
  }
  
  .task-details {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .task-name {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .task-info {
    color: #555;
    margin-bottom: 15px;
    padding: 2%;
  }
  
  .task-time,
  .task-notes {
    color: #555;
    margin-bottom: 10px;
  }
  
  .sticky-notes-container {
    margin-top: 20px;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .sticky-note-input {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .add-sticky-note-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-sticky-note-btn:hover {
    background-color: #0056b3;
  }
  

@media (max-width : 1100px){
    *{
        font-size: 20px;
    }
}

@media (max-width : 1320px){
    .btnlgout{
        margin: 8px auto;
    }
}

@media (max-width : 900px){
    .cmntss{
        font-size: 1.2rem;
    }
    .cmntusr{
        font-size: 1rem;
    }
}

@media (max-width: 768px) {

    .reeel{
        width: 50px;
        height: 50px;
    }

    *, .usr div, .about{
        font-size: 14px;
    }

    .reactns img, .about img, .user img, .userid img{
        width: 15px;
        height: 15px;
    }

    .reactions img{
        width: 15px;
        height: 15px;
    }

    .sgnup, .logn{
        width: 70%;
    }
  }

  @media (max-width : 482px){
    .sprt{
        position: absolute;
        top : 3.3%;
        left: 25%;
    }
    .home{
        position: absolute;
        top : 3.2%;
        left: 50%;
    }

    .btnlgout{
        margin: 0;
    }
  }